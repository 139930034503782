import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/product/overview/engineering-analytics-dashboard.png'
import Layout from '../../components/Layout'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import FooterBlock from '../../components/blocks/FooterBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import ToolsBlock from '../../components/blocks/ToolsBlock'
import Blurb from '../../components/system/Blurb'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import Box from '../../components/primitives/Box'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/insights/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/metrics-380.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/survey-narrow.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/initiatives-430.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    engineeringTeams: file(
      relativePath: { eq: "images/product/insights/pr-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1322)
      }
    }
    codeoverview: file(
      relativePath: { eq: "images/product/overview/new-code-overview.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1247)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement3: file(
      relativePath: { eq: "images/product/overview/working-agreement.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`

const EngineeringAnalyticsDashboardPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Engineering Analytics Dashboard"
      variant="dark"
      isNew
      description="Visualize the metrics and insights you need to drive engineering effectiveness in your organization."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          title={<>Real-time engineering analytics dashboard</>}
          content="Visualize the metrics and insights you need to drive engineering effectiveness in your organization."
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <DarkLogoBlock />
        <LeadBlock
          heading="Track the right things at every level of the organization"
          content="Follow DORA, SPACE, and other research-backed developer productivity metrics with granular dashboards."
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(32)}
            image={getImage(data.codeoverview)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteMosaicBlock
          title="Remove process blockers and improve delivery"
          content={
            <>
              Swarmia turns GitHub and issue tracker data into actionable
              dashboards that help teams quickly identify where the bottlenecks
              are.
              <br />
              <br />
              The real-time dashboards let you track developer productivity on
              an organizational level, or dig deeper into team-level
              performance.{' '}
            </>
          }
          image={getImage(data.mosaicRight)!}
          imageAlign="right"
          quote={quotes.walter}
        />
        <QuoteMosaicBlock
          title="Go beyond system metrics"
          content={
            <>
              Use Swarmia’s built-in developer experience surveys to get a
              holistic understanding of your engineering organization.
              <br />
              <br />
              The survey results are displayed in an easily interpretable
              heatmap, helping you identify the teams needing more support.{' '}
            </>
          }
          image={getImage(data.mosaicLeft)!}
          quote={quotes.dave3}
        />
        <QuoteMosaicBlock
          title="Take surprises out of software delivery"
          content={
            <>
              The initiatives dashboard helps you ensure all your projects
              progress as planned.
              <br />
              <br />
              You can easily see the status of different projects, and see which
              people have contributed to different initiatives.
            </>
          }
          image={getImage(data.mosaicRight2)!}
          imageAlign="right"
          quote={quotes.dan}
        />
        <LeadBlock
          heading="Metrics alone don’t drive change"
          content="Adopt working agreements to stay accountable for things that are important to your team."
          link={{
            title: 'Read more about working agreements',
            href: '/product/working-agreements/',
          }}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(48)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Adopt proven ways of working"
              text="Work with your team to set relevant and achievable targets."
              image={getImage(data.workingAgreement1)}
            />,
            <Blurb
              key="2"
              title="Automate feedback loops"
              text="Get real-time alerts in Slack to keep up with your agreements."
              image={getImage(data.workingAgreement2)}
            />,
            <Blurb
              key="3"
              title="Track progress"
              text="Avoid slipping and celebrate progress with frequent recaps."
              image={getImage(data.workingAgreement3)}
            />,
          ]}
        />
        <ToolsBlock />

        <QuoteBlock person="maryanna" />
        <FooterBlock
          productPages={['investmentBalance', 'workingAgreements']}
        />
      </GlobalStyleReset>
    </Layout>
  )
}

export default EngineeringAnalyticsDashboardPage
